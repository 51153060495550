import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {routeNames} from "../../Navigation/routeNames";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {useHistory} from "react-router-dom";
import FormHeader from "../../Form/FormHeader";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../Button/MCButton";
import TopBar from "../Layouts/Layout/Components/TopBar/TopBar";

const PrfReports = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    /** Page setup */
    useEffect(() => {
        const navigationItems: NavigationItem[] = [
            {
                name: routeNames.overview.name,
                path: routeNames.overview.path
            },
            {
                name: routeNames.auditList.name,
                path: routeNames.auditList.path
            },
            {
                name: routeNames.reports.name,
                path: routeNames.reports.path
            }
        ];
        dispatch(setNavigationItems(navigationItems));
    }, []);

    // Goes to Audit Type report
    const goToAuditTypeReport = () => {
        history.push(routeNames.auditTypeReport.path);
    };

    // Goes to Audit Compliance report
    const goToAuditComplianceReport = () => {
        history.push(routeNames.auditComplianceReport.path);
    };

    // Goes to Outcome report
    const goToOutcomeReport = () => {
        history.push(routeNames.outcomeReport.path);
    };

    // Goes to Quality report
    const goToQualityReport = () => {
        history.push(routeNames.qualityReport.path);
    };

    // Goes to Venue report
    const goToVenueComplianceReport = () => {
        history.push(routeNames.venueReport.path);
    };

    // Goes to failed questions report
    const goToFailedQuestionsReport = () => {
        history.push(routeNames.failedQuestions.path);
    };

    return (
        <React.Fragment>
            <TopBar titleText="Reports" />
            <div className="page-container pt-4">
                <FormHeader headerName={"Select a report to view"} />
                <div className="row-modifier">
                    <div className="row ml-0 mr-0 mt-3">
                        <div className="col-6 report-button">
                            <MCButton
                                size={ButtonSize.ExtraLarge}
                                innerValue={"Audit Type Report"}
                                onClick={goToAuditTypeReport}
                                colour={ButtonColourOptions.DarkBlue}
                                className={"w-100"}
                            />
                        </div>
                        <div className="col-6 report-button">
                            <MCButton
                                size={ButtonSize.ExtraLarge}
                                innerValue={"Compliance Report"}
                                onClick={goToAuditComplianceReport}
                                colour={ButtonColourOptions.DarkBlue}
                                className={"w-100"}
                            />
                        </div>
                        <div className="col-6 report-button">
                            <MCButton
                                size={ButtonSize.ExtraLarge}
                                innerValue={"Outcome Report"}
                                onClick={goToOutcomeReport}
                                colour={ButtonColourOptions.DarkBlue}
                                className={"w-100"}
                            />
                        </div>
                        <div className="col-6 report-button">
                            <MCButton
                                size={ButtonSize.ExtraLarge}
                                innerValue={"Quality Report"}
                                onClick={goToQualityReport}
                                colour={ButtonColourOptions.DarkBlue}
                                className={"w-100"}
                            />
                        </div>
                        <div className="col-6 report-button">
                            <MCButton
                                size={ButtonSize.ExtraLarge}
                                innerValue={"Failed Questions Report"}
                                onClick={goToFailedQuestionsReport}
                                colour={ButtonColourOptions.DarkBlue}
                                className={"w-100"}
                            />
                        </div>
                        <div className="col-6 report-button">
                            <MCButton
                                size={ButtonSize.ExtraLarge}
                                innerValue={"Venue Report"}
                                onClick={goToVenueComplianceReport}
                                colour={ButtonColourOptions.DarkBlue}
                                className={"w-100"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default PrfReports;
