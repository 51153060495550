import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {routeNames} from "../../../Navigation/routeNames";
import {NavigationItem} from "../../../../store/navigation/actions/NavigationActionTypes";
import {setNavigationItems} from "../../../../store/navigation/actions/NavigationActions";
import {useUserData} from "../../../Hooks/useUserData";
import {createNewAudit} from "../../../../store/audit/actions/AuditActions";
import {WithServiceState} from "store-fetch-wrappers";
import AuditFormParent from "./Components/Parent/AuditFormParent";
import {RootStore} from "../../../../store/Store";
import TopBar from "../../Layouts/Layout/Components/TopBar/TopBar";

const AuditFormWrapper = WithServiceState(AuditFormParent);

/** Parent component for the audit form */
const NewAudit = () => {
    const dispatch = useDispatch();
    const user = useUserData();
    const auditStore = useSelector((state: RootStore) => state.audit);
    useEffect(() => {
        const navigationItems: NavigationItem[] = [
            {
                name: routeNames.overview.name,
                path: routeNames.overview.path
            },
            {
                name: routeNames.auditList.name,
                path: routeNames.auditList.path
            },
            {
                name: routeNames.reports.name,
                path: routeNames.reports.path
            }
        ];
        dispatch(setNavigationItems(navigationItems));
    }, []);

    /** Waits for user data to be initialised before creating new audit */
    useEffect(() => {
        if (user.username.length === 0) return;

        dispatch(createNewAudit(user));
    }, [user]);
    return (
        <React.Fragment>
            <TopBar titleText="New Audit" />

            <div className="page-container pt-4">
                <AuditFormWrapper
                    showLoadingText={true}
                    loaderWheelType={"three-ring"}
                    loaderType={"overlay"}
                    placeholderBlockCount={1}
                    {...auditStore}
                />
            </div>
        </React.Fragment>
    );
};

export default NewAudit;
