import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useUserData} from "../../../Hooks/useUserData";
import {RootStore} from "../../../../store/Store";
import {useParams} from "react-router-dom";
import {setNavigationItems} from "../../../../store/navigation/actions/NavigationActions";
import {getNavItemsForReadOnlyAuditRouteForUser} from "./Helpers/readonlyAuditHelpers";
import {fetchAuditById} from "../../../../store/audit/actions/AuditActions";
import {WithServiceState} from "store-fetch-wrappers";
import ReviewAuditFormParent from "./Components/ReviewAuditFormParent";
import useMcConfig from "../../../Hooks/useMcConfig";
import TopBar from "../../Layouts/Layout/Components/TopBar/TopBar";

const ServiceWrapper = WithServiceState(ReviewAuditFormParent);

const ReviewAudit = () => {
    const dispatch = useDispatch();
    const user = useUserData();
    const auditStore = useSelector((state: RootStore) => state.audit);
    const params: any = useParams();
    const {config} = useMcConfig();

    useEffect(() => {
        if (user.username.length === 0) return;
        if (!config.auth) return;
        dispatch(
            setNavigationItems(getNavItemsForReadOnlyAuditRouteForUser(user, +params.id, config))
        );

        dispatch(fetchAuditById(+params.id));
    }, [user, config]);
    return (
        <React.Fragment>
            <TopBar titleText="Review Audit" />

            <div className="page-container pt-4">
                <ServiceWrapper
                    loaderWheelType={"three-ring"}
                    loaderType={"overlay"}
                    showLoadingText={false}
                    placeholderBlockCount={1}
                    {...auditStore}
                />
            </div>
        </React.Fragment>
    );
};

export default ReviewAudit;
