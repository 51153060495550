import React, {useEffect, useState} from "react";
import {
    YesAnswerMonthlyQuestionResult,
    YesAnswerMonthlyReportResult
} from "../../../../../../api/prf";
import {
    addAllOutcomeResultsTogether,
    OutcomeResults,
    sortOutcomeReportResultRowsToVertical
} from "../Helpers/outcomeReportHelpers";
import {useReportDateView} from "../../../../../Hooks/useReportDateView";
import OutcomeReportListHeader from "./OutcomeReportListHeader";
import OutcomeReportListItem from "./OutcomeReportListItem";

const OutcomeReportTable = (props: YesAnswerMonthlyReportResult) => {
    const reportDateView = useReportDateView();
    const [questions, setQuestions] = useState<YesAnswerMonthlyQuestionResult[]>(
        addAllOutcomeResultsTogether(props)
    );
    const [outcomeResults, setOutcomeResults] = useState<OutcomeResults[]>([]);

    useEffect(() => {
        const questionsData = addAllOutcomeResultsTogether(props);
        setQuestions(questionsData);
        setOutcomeResults(sortOutcomeReportResultRowsToVertical(questionsData, reportDateView));
    }, [props]);

    return (
        <React.Fragment>
            <div className="mt-3 table-list">
                <table className="pulse-table d-block overflow-auto fadeIn">
                    <tbody>
                        <OutcomeReportListHeader {...questions} />
                        {outcomeResults.map((result: OutcomeResults, index: number) => {
                            return <OutcomeReportListItem {...result} key={index} />;
                        })}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
};

export default OutcomeReportTable;
