import {ReportResponse} from "../../../api/prf";

/** Will sort audit type, compliance and venue report results by score */
export function sortReportResultResponseToSortedResults<T>(
    prefix: string,
    results: T[] | undefined
): SortedReportResults<T>[] {
    /** Generate base object */
    const reportResults: SortedReportResults<T>[] = generateBlankReportResults(prefix);

    // if results are undefined, we want to return empty array which can be used to handle ui
    if (!results) return [];
    if (results.length === 0) return [];

    for (const result of results) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (result.score === 100) {
            reportResults[0].results.push(result);
            continue;
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (result.score >= 90 && result.score < 100) {
            reportResults[1].results.push(result);
            continue;
        }

        // Score is below 90
        reportResults[2].results.push(result);
    }

    return sortResultsByNumericalValue(reportResults);
}

/** Sorts the report results by numerical order */
function sortResultsByNumericalValue<T>(
    reportResults: SortedReportResults<T>[]
): SortedReportResults<T>[] {
    for (const reportResult of reportResults) {
        reportResult.results.sort((a, b) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (a.score > b.score) {
                return -1;
            }

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (a.score < b.score) {
                return 1;
            }
            return 0;
        });
    }

    return reportResults;
}

/** Generates blank report structure */
function generateBlankReportResults<T>(prefix: string): SortedReportResults<T>[] {
    return [
        {
            headerText: `${prefix} with 100% compliance`,
            results: []
        },
        {
            headerText: `${prefix} with 90%-99% compliance`,
            results: []
        },
        {
            headerText: `${prefix} with less than 90% compliance`,
            results: []
        }
    ];
}

/** Custom interface to filter all the results */
export interface SortedReportResults<T> {
    results: T[];
    headerText: string;
}

/** Gets the class name for the dots based on score */
export function getDotClassName<T>(result: T) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (+result.score === 100) {
        return "coloured-dots green";
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (result.score >= 90 && result.score < 100) {
        return "coloured-dots yellow";
    }

    return "coloured-dots red";
}

export function generateBlankReportResponse(): ReportResponse {
    return {
        auditTypes: [],
        date: {startDate: 0, endDate: 0},
        results: []
    };
}
