import React, {useEffect, useState} from "react";
import {AuditListRequest, AuditListResponse} from "../../../../api/prf";
import {formatUnixToDDMMYYYY} from "../../../../utils/momentUtils";
import {getUiFriendlyText} from "../../../../utils/textUtils";
import {PulseTableWithServerPagination} from "pulse_table";
import AuditTrailLogsFilters from "./AuditTrailLogsFilters";
import FilterContainer from "../../../Filters/FilterContainer";
import {useDispatch} from "react-redux";
import {
    getPagedAudits,
    nullifyPagedAuditStore
} from "../../../../store/auditTrailLogs/actions/AuditTrailLogsActions";
import {pagedRequestConfig} from "../../../Filters/helpers/filterHelpers";
import Tooltip from "../../../Tooltip/Tooltip";
import Icon, {IconType} from "../../../Icon/Icon";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../../Navigation/routeNames";
import {usePageUrl} from "../../../Hooks/usePageUrl";
import AuthAmI from "../../../AuthAmI/AuthAmI";
import {StaffAccessLevel} from "../../../../api/staff";
import {getDotClassName} from "../../../../store/reports/helpers/reportHelpers";
import AuditTrailLogsHistory from "./History/AuditTrailLogsHistory";
import TableActionContainer from "../../../Table/TableActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";

const AuditTrailLogsTable = (props: AuditListResponse) => {
    const dispatch = useDispatch();
    const [auditListRequest, setAuditListRequest] = useState<AuditListRequest>();
    const history = useHistory();
    const {searchQueries} = usePageUrl();

    useEffect(() => {
        return function () {
            dispatch(nullifyPagedAuditStore());
        };
    }, []);

    const fetchPagedAudits = (request: AuditListRequest) => {
        if (!auditListRequest) {
            setAuditListRequest(request);
            fetchPagedReports(request);
            return;
        }
        if (JSON.stringify(request) === JSON.stringify(auditListRequest)) return;
        setAuditListRequest(request);
        fetchPagedReports(request);
    };

    // Fetches Audits
    const fetchPagedReports = (request: AuditListRequest) => {
        dispatch(getPagedAudits(request));
    };

    const editAudit = (auditId: number) => {
        const pathName = `${routeNames.editAudit.path}/${auditId}`;
        history.push({
            pathname: pathName,
            search: searchQueries
        });
    };

    const reviewAudit = (auditId: number) => {
        history.push({
            pathname: `${routeNames.reviewAudit.path}/${auditId}`,
            search: searchQueries
        });
    };

    return (
        <React.Fragment>
            <FilterContainer closed={true}>
                <AuditTrailLogsFilters onRequestChanged={fetchPagedAudits} />
            </FilterContainer>
            <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                <div className="row-modifier">
                    <TableActionContainer>
                        <div className="col d-flex justify-content-end pl-0 pr-0 mb-3 mt-3">
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"New Audit"}
                                onClick={() => {
                                    const path = routeNames.newAudit.path;
                                    history.push(path);
                                }}
                                roundedCorner
                                colour={ButtonColourOptions.Yellow}
                            />
                        </div>
                    </TableActionContainer>
                </div>
            </AuthAmI>
            <PulseTableWithServerPagination
                items={toTableRow(props)}
                headers={{
                    staffMember: "Staff Member",
                    calendar: "Calendar",
                    venue: "Venue",
                    date: "Date",
                    auditType: "Audit Type",
                    score: "Score",
                    actions: "Actions"
                }}
                noItemsSection={
                    <div className="row ml-0 mr-0 fadeIn">
                        <div className="col">
                            <h5 className="text-center p-3">
                                There are no audits that match these filters
                            </h5>
                        </div>
                    </div>
                }
                customRenderers={{
                    actions: (item: AuditTrailLogsTableRow) => (
                        <div className="row ml-0 mr-0">
                            <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                                <div className="icon-tooltip-wrapper-md mr-3">
                                    <Tooltip
                                        tooltipText={"Edit this audit"}
                                        size={"md"}
                                        place={"left"}
                                    >
                                        <Icon
                                            onClick={() => editAudit(item.actions)}
                                            rootElement={"Span"}
                                            icon={IconType.Edit}
                                            size={"Medium"}
                                            className="cursor-pointer"
                                        />
                                    </Tooltip>
                                </div>

                                <AuditTrailLogsHistory {...item} />
                            </AuthAmI>
                            <AuthAmI
                                accessLevels={[
                                    StaffAccessLevel.DutyManager,
                                    StaffAccessLevel.Staff
                                ]}
                            >
                                <div className="icon-tooltip-wrapper-md mr-3">
                                    <Tooltip
                                        tooltipText={"Review this audit"}
                                        size={"md"}
                                        place={"left"}
                                    >
                                        <Icon
                                            onClick={() => reviewAudit(item.actions)}
                                            rootElement={"Span"}
                                            icon={IconType.Review}
                                            size={"Medium"}
                                            className="cursor-pointer"
                                        />
                                    </Tooltip>
                                </div>
                            </AuthAmI>
                        </div>
                    ),
                    score: (item: AuditTrailLogsTableRow) => (
                        <p className="mb-0">
                            {item.score}
                            <span className={`mb-0 ml-2 ${getDotClassName(item)}`} />
                        </p>
                    )
                }}
                totalResults={props.totalResults}
                resultsPerPage={pagedRequestConfig.resultsPerPage}
                onPageChanged={(newPageNum) => {
                    if (!auditListRequest) return;
                    fetchPagedReports({
                        ...auditListRequest,
                        pageNum: newPageNum
                    });
                }}
            />
        </React.Fragment>
    );
};

export default AuditTrailLogsTable;

export interface AuditTrailLogsTableRow {
    staffMember: string;
    calendar: string;
    venue: string;
    date: string;
    auditType: string;
    score: number;
    actions: number;
}

function toTableRow(response: AuditListResponse): AuditTrailLogsTableRow[] {
    return response.results.map((item) => {
        return {
            staffMember: item.clinician.staffName,
            calendar: item.grsLocation?.calendarName || "Calendar Deleted",
            venue: item.grsLocation?.venueName || "Venue Deleted",
            date: formatUnixToDDMMYYYY(item.creationDate),
            auditType: getUiFriendlyText(item.auditType),
            score: item.score,
            actions: item.auditId
        };
    });
}
