import React from "react";
import {
    getDotClassName,
    SortedReportResults
} from "../../../../../../store/reports/helpers/reportHelpers";
import {ReportResult} from "../../../../../../api/prf";
import {getUiFriendlyText} from "../../../../../../utils/textUtils";
import {formatUnixToDDMMYYYYHHmm} from "../../../../../../utils/momentUtils";
import {pagedRequestConfig} from "../../../../../Filters/helpers/filterHelpers";
import {PulseTableWithLocalPagination} from "pulse_table";
import FormHeader from "../../../../../Form/FormHeader";

const ComplianceReportListComplianceBlock = (props: SortedReportResults<ReportResult>) => {
    return (
        <React.Fragment>
            <div className="mt-3">
                <FormHeader headerName={props.headerText} />
                <PulseTableWithLocalPagination
                    items={sortComplianceReportToTableRow(props.results)}
                    headers={{
                        staffDetails: "Staff Details",
                        calendar: "Calendar",
                        auditType: "Audit Type",
                        date: "Date",
                        score: "Score"
                    }}
                    noItemsSection={
                        <div className="row ml-0 mr-0 fadeIn">
                            <div className="col">
                                <h5 className="text-center p-3">There are no {props.headerText}</h5>
                            </div>
                        </div>
                    }
                    customRenderers={{
                        score: (item: TableRow) => (
                            <p className="mb-0">
                                {item.score}
                                <span className={`mb-0 ml-2 ${getDotClassName(item)}`} />
                            </p>
                        )
                    }}
                    resultsPerPage={pagedRequestConfig.resultsPerPage}
                />
            </div>
        </React.Fragment>
    );
};

export default ComplianceReportListComplianceBlock;

interface TableRow {
    staffDetails?: string;
    auditType: string;
    calendar: string;
    date: string;
    score: string;
}

function sortComplianceReportToTableRow(reportResults: ReportResult[]): TableRow[] {
    return reportResults.map((item) => {
        return {
            staffDetails: item.staffMember?.staffName,
            calendar: item.grsLocation.calendarName,
            auditType: getUiFriendlyText(item.auditType),
            date: formatUnixToDDMMYYYYHHmm(item.date),
            score: item.score.toString()
        };
    });
}
