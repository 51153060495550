import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {routeNames} from "../../Navigation/routeNames";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import BackgroundContainer from "../../Containers/BackgroundContainer";
import AuditTypeComplianceStats from "./Components/AuditTypeComplianceStats";
import CalendarAuditListComplianceStats from "./Components/CalendarAuditComplianceStats";
import StaffComplianceStats from "./Components/StaffComplianceStats";
import VenueComplianceStats from "./Components/VenueComplianceStats";
import OverallComplianceStats from "./Components/OverallComplianceStats";
import TopBar from "../Layouts/Layout/Components/TopBar/TopBar";
/** Parent Component for PrfAudits  */
const Overview = () => {
    const dispatch = useDispatch();
    /** Page setup */
    useEffect(() => {
        const navigationItems: NavigationItem[] = [
            {
                name: routeNames.overview.name,
                path: routeNames.overview.path
            },
            {
                name: routeNames.auditList.name,
                path: routeNames.auditList.path
            },
            {
                name: routeNames.reports.name,
                path: routeNames.reports.path
            }
        ];
        dispatch(setNavigationItems(navigationItems));
    }, []);
    return (
        <React.Fragment>
            <TopBar titleText="PRF Overview" />
            <BackgroundContainer background={"background-stars"}>
                <AuditTypeComplianceStats />
            </BackgroundContainer>
            <BackgroundContainer background={"background-chevrons"}>
                <CalendarAuditListComplianceStats />
            </BackgroundContainer>
            <BackgroundContainer background={"background-stars"}>
                <StaffComplianceStats />
            </BackgroundContainer>
            <BackgroundContainer background={"background-chevrons"}>
                <OverallComplianceStats />
            </BackgroundContainer>
            <BackgroundContainer background={"background-stars"}>
                <VenueComplianceStats />
            </BackgroundContainer>
        </React.Fragment>
    );
};

export default Overview;
