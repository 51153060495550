import React, {useEffect, useState} from "react";
import ReportContainer from "../../Components/ReportContainer";
import {routeNames} from "../../../../Navigation/routeNames";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../../store/Store";
import {NavigationItem} from "../../../../../store/navigation/actions/NavigationActionTypes";
import {setNavigationItems} from "../../../../../store/navigation/actions/NavigationActions";
import {ReportRequest} from "../../../../../api/prf";
import {
    fetchAuditTypeReport,
    nullifyAuditTypeReportStore
} from "../../../../../store/reports/auditType/actions/AuditTypeReportActions";
import {WithServiceState} from "store-fetch-wrappers";
import AuditTypeReportList from "./Components/AuditTypeReportList";
import FilterContainer from "../../../../Filters/FilterContainer";
import ReportFilters from "../../Components/Filters/Components/ReportFilters";
import ReportFooter from "../../Components/ReportFooter";
import TopBar from "../../../Layouts/Layout/Components/TopBar/TopBar";

const AuditTypeReportListWrapper = WithServiceState(AuditTypeReportList);

const AuditTypeReport = () => {
    const dispatch = useDispatch();
    const [reportRequest, setReportRequest] = useState<ReportRequest>();

    const auditTypeReportStore = useSelector((state: RootStore) => state.auditTypeReport);
    /** Page setup */
    useEffect(() => {
        const navigationItems: NavigationItem[] = [
            {
                name: routeNames.overview.name,
                path: routeNames.overview.path
            },
            {
                name: routeNames.auditList.name,
                path: routeNames.auditList.path
            },
            {
                name: routeNames.reports.name,
                path: routeNames.reports.path
            },
            {
                name: routeNames.auditTypeReport.name,
                path: routeNames.auditTypeReport.path
            }
        ];
        dispatch(setNavigationItems(navigationItems));

        return function () {
            dispatch(nullifyAuditTypeReportStore());
        };
    }, []);

    /** Fires when audit type report request has changed */
    const onAuditTypeReportRequestChanged = (request: ReportRequest) => {
        if (!reportRequest) {
            setReportRequest(request);
            fetchPagedReports(request);
            return;
        }
        if (JSON.stringify(request) === JSON.stringify(reportRequest)) return;
        setReportRequest(request);
        fetchPagedReports(request);
    };

    // Fetches Audits
    const fetchPagedReports = (request: ReportRequest) => {
        dispatch(fetchAuditTypeReport(request));
    };
    return (
        <>
            <TopBar titleText="Audit Reports" />
            <ReportContainer>
                <FilterContainer closed={false}>
                    <ReportFilters
                        onRequestChanged={onAuditTypeReportRequestChanged}
                        showCalendarFilter={false}
                        showStaffFilter={false}
                        showVenueFilter={false}
                        reduceAuditTypeFilters={false}
                    />
                </FilterContainer>
                <AuditTypeReportListWrapper
                    loaderWheelType={"three-ring"}
                    loaderType={"overlay"}
                    showLoadingText={false}
                    placeholderBlockCount={1}
                    {...auditTypeReportStore}
                />
                <ReportFooter />
            </ReportContainer>
        </>
    );
};

export default AuditTypeReport;
